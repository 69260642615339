import typesPDFSettings         from './constant';

const INITIAL_STATE = ({
  header: true,
  footer: true,
  patientInfo: true,
  fetching: true,
  data: {},
  saving: false,
  emptyResponse: false,
  selectedResponse: false,
  selectedComments: false,
  qualifier: true,
  moreInfoQualifier: true,
  qualifierDeficiency: true,
  tableQualifierDeficiency: true,
  moreInfoQualifierDeficiency: true,
  dataProfessional: true,
  dataAttendance: true,
  dataWorkspace: false,
});

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case typesPDFSettings.PDF_SETTINGS_SET_HEADER: {
      return {
        ...state,
        header: action.payload
      }
    }
    case typesPDFSettings.PDF_SETTINGS_CHANGE_CONFIG: {
      const { id, value } = action.payload;
      return {
        ...state,
        [id]: value
      }
    }
    case typesPDFSettings.PDF_SETTINGS_SET_FOOTER: {
      return {
        ...state,
        footer: action.payload
      }
    }
    case typesPDFSettings.PDF_SETTINGS_SET_VIEW_PATIENT: {
      return {
        ...state,
        patientInfo: action.payload
      }
    }
    case typesPDFSettings.PDF_SETTINGS_FETCH_REQUEST: {
      return {
        ...state,
        fetching: true
      }
    }
    case typesPDFSettings.PDF_SETTINGS_FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        fetching: false
      }
    }
    case typesPDFSettings.PDF_SETTINGS_SAVE_REQUEST: {
      return {
        ...state,
        saving: true
      }
    }
    case typesPDFSettings.PDF_SETTINGS_SAVE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        saving: false
      }
    }
    case typesPDFSettings.GET_PDF_INFO_REQUEST: {
      return {
        ...state,
        fetching: true
      }
    }
    case typesPDFSettings.GET_PDF_INFO_FAILED: {
      return {
        ...state,
        fetching: true,
        data: {}
      }
    }
    case typesPDFSettings.GET_PDF_INFO_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetching: false
      }
    }
    case typesPDFSettings.RESET_STATE: {
      return {
        ...INITIAL_STATE,
        header: state.header,
        footer: state.footer,
        patientInfo: state.patientInfo
      }
    }
    default: return state;
  }
};