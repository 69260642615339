export const ROUTE_BASIC = 'app';
export const REGISTER = 'register';
export const AUTOLOGIN = 'autologin';
export const PURCHASE = 'purchase';
export const STARTED = 'started';
export const DASHBOARD = 'dashboard';
export const RECOVER = 'recover';
export const USERS = 'users';
export const MANAGER = 'manager';
export const INVITE = 'invite';
export const PLANS = 'plans';
export const WORKSPACES = 'workspaces';
export const PERFIL = 'perfil';
export const PERMISSION = 'permission';
export const PROFESSIONAL = 'professional';
export const POSITION_PROFESSIONAL = 'positionProfessional';
export const CLIENT = 'client';
export const CONFIGURATION = 'configuration';
export const BILLING = 'billing';
export const INTEGRATIONS = 'integrations';
export const SERVICES = 'services';
export const MEDICAL_RECORD = 'medicalRecord';
export const PRESCRIPTION = 'prescription';
export const CERTIFICATE = 'certificate';
export const REFERRAL = 'referral';
export const REPORT = 'report';
export const REQUEST = 'request';
export const FIELDS = 'fields';
export const FORMS = 'forms';
export const FORMSENGINE = 'formsEngine';
export const MODEL = 'model';
export const ANSWER = 'answer';
export const LIST = 'list';
export const CREATE = 'create';
export const UPDATE = 'update';
export const SECTIONS = 'sections';
export const EXERCISES = 'exercises';
export const SHCEDULES = 'schedules';
export const DOCUMENTS = 'docs';
export const MODELS = 'models';
export const DOCUMENT = 'document';
export const USECORE = 'usecore';

export const REPORTS = 'reports';
export const SIGNATURE = 'signature';
export const QUALIFIERS = 'qualifiers';
export const CORELINE = 'coreline';
export const MODELS_DASHBOARDS = 'modelsDashboards';

export const AUTHENTICITY = 'authenticity';

export const CHECK = 'check';

export const ROUTE_REGISTER = `${REGISTER}`;

export const ROUTE_STARTED = `${STARTED}`;

export const ROUTE_DASHBOARD = `${DASHBOARD}`;

export const ROUTE_INVITE = `${INVITE}`;

export const ROUTE_MANAGER = `${MANAGER}`;
export const ROUTE_MANAGER_CREATE = `${ROUTE_MANAGER}/${CREATE}`;

export const ROUTE_WORKSPACES = `${WORKSPACES}`;
export const ROUTE_WORKSPACES_USERS_UPDATE = `${WORKSPACES}/${UPDATE}`;

export const ROUTE_USERS = `${USERS}`;
export const ROUTE_USERS_CREATE = `${ROUTE_USERS}/${CREATE}`;
export const ROUTE_USERS_UPDATE = `${ROUTE_USERS}/${UPDATE}`;

export const ROUTE_PLANS = `${PLANS}`;

export const ROUTE_PERFIL = `${PERFIL}`;
export const ROUTE_PERFIL_PROFESSIONAL = `${ROUTE_PERFIL}/${PROFESSIONAL}`;
export const ROUTE_PERFIL_CONFIGURATION = `${ROUTE_PERFIL}/${CONFIGURATION}`;
export const ROUTE_PERFIL_CONFIGURATION_MEDICAL_RECORD = `${ROUTE_PERFIL_CONFIGURATION}/${MEDICAL_RECORD}`;

export const ROUTE_PROFESSIONAL_UPDATE = `${PROFESSIONAL}/${UPDATE}`;

export const ROUTE_CLIENT = `${CLIENT}`;
export const ROUTE_CLIENT_CREATE = `${ROUTE_CLIENT}/${CREATE}`;
export const ROUTE_CLIENT_UPDATE = `${ROUTE_CLIENT}/${UPDATE}`;

export const ROUTE_BILLING = `${BILLING}`;
export const ROUTE_MEDICAL_RECORD = `${MEDICAL_RECORD}`;

export const ROUTE_SERVICES = `${SERVICES}`;

export const ROUTE_FORMS_MODEL_ID_LIST_ANSWER = (param) =>
  `${FORMS}/${MODEL}/${param}/${LIST}/${ANSWER}`;
