export default ({
  fieldSelected: {},
  fetching: {
    fetchSearchFields: false,
    fetchFieldSelected: false,
    dashboardModels: false,
  },
  api: {
    listFields: [],
    dashboardModels: []
  }
});