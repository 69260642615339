
export const FETCH_SEARCH_FIELDS_DASHBOARD_MODEL_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          fetchSearchFields: true
        }
      }
    }
  });
}

export const FETCH_SEARCH_FIELDS_DASHBOARD_MODEL_SUCCESS =  (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          fetchSearchFields: false
        },
        api: {
          ...state.medicalRecord.modelDashboard.api,
          listFields: action.payload
        }
      }
    }
  });
}


export const FETCH_SEARCH_FIELDS_DASHBOARD_MODEL_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          fetchSearchFields: false
        }
      }
    }
  });
}
export const FETCH_GET_FIELD_MODEL_VERSION_BY_ID_DASHBOARD_MODEL_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          fetchFieldSelected: true
        }
      }
    }
  });
}

export const FETCH_GET_FIELD_MODEL_VERSION_BY_ID_DASHBOARD_MODEL_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          fetchFieldSelected: false
        },
        fieldSelected: action.payload
      }
    }
  });
}

export const FETCH_GET_FIELD_MODEL_VERSION_BY_ID_DASHBOARD_MODEL_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          fetchFieldSelected: false
        }
      }
    }
  });
}

export const CREATE_INDICATORS_DASHBOARD_MODEL_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          createIndicators: true
        }
      }
    }
  });
}

export const CREATE_INDICATORS_DASHBOARD_MODEL_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          createIndicators: false
        }
      }
    }
  });
}

export const CREATE_INDICATORS_DASHBOARD_MODEL_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          createIndicators: false
        }
      }
    }
  });
}

export const LIST_INDICATORS_DASHBOARD_MODEL_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          dashboardModels: true
        }
      }
    }
  });
}

export const LIST_INDICATORS_DASHBOARD_MODEL_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          dashboardModels: false
        },
        dashboardModels: action.payload
      }
    }
  });
}

export const LIST_INDICATORS_DASHBOARD_MODEL_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          dashboardModels: false
        }
      }
    }
  });
}

export const UPDATE_INDICATOR_DASHBOARD_MODEL_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          updateIndicator: true
        }
      }
    }
  });
}

export const UPDATE_INDICATOR_DASHBOARD_MODEL_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          updateIndicator: false
        }
      }
    }
  });
}

export const UPDATE_INDICATOR_DASHBOARD_MODEL_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          updateIndicator: false
        }
      }
    }
  });
}

export const DUPLICATE_INDICATOR_DASHBOARD_MODEL_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          duplicateIndicator: true
        }
      }
    }
  });
}

export const DUPLICATE_INDICATOR_DASHBOARD_MODEL_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          duplicateIndicator: false
        }
      }
    }
  });
}

export const DUPLICATE_INDICATOR_DASHBOARD_MODEL_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          duplicateIndicator: false
        }
      }
    }
  });
}

export const DELETE_INDICATOR_DASHBOARD_MODEL_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          deleteIndicator: true
        }
      }
    }
  });
}

export const DELETE_INDICATOR_DASHBOARD_MODEL_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          deleteIndicator: false
        }
      }
    }
  });
}

export const DELETE_INDICATOR_DASHBOARD_MODEL_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      modelDashboard: {
        ...state.medicalRecord.modelDashboard,
        fetching: {
          ...state.medicalRecord.modelDashboard.fetching,
          deleteIndicator: false
        }
      }
    }
  });
}